.centerspace{
    display: flex;
    justify-content: center;
    margin-left: 20vh;
}
.inputfield{
    width: 90% !important;
    height: 100% !important;
    margin-left: -3.6vh !important;
    border-radius: 0vh 1vh 1vh 0vh !important;
    border: 0 !important;
    border: 1px solid black !important;
}
.option{
    font-size: 2vh;
    height: 100%;
    background-color: white;
    border-radius: 1vh 0vh 0 1vh ;
    border: 0;
    border: 1px solid #000;
}
.searchicon{
    margin-left: -5vh !important;
    position: relative !important;
}
.icon{
    position: absolute !important;
    margin-top: -2vh !important;
    font-size: 2vh !important;
    margin-left: 1vh;
}
.main1{
    /* 40vh height dena hai isme mt-40vh */
    margin-top: 0vh !important;
    background-image: linear-gradient(30deg, #0048bd, #44a7fd);
    /* box-shadow: 5px 1px 5px black; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    animation: vijay;
    animation-duration: 1s;
}
@keyframes vijay {
    0%{
      visibility: initial;
      background: transparent;
      box-shadow: none;
    }
    100%{
        background-color: #3eadcf;
    }
}
.logo{
    font-family: Comic Sans MS;
    font-weight: 700;
    margin-left: 8vh;
}
.post{
    /* box-shadow: 3px 2px 5px black ; */
    /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; */
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    /* box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
