.furnishing,.unfurnishing{
    border-radius:0 50vh 50vh 0;
    height: 50vh;
}
.semi_furnishing{
    border-radius:50vh 0 0 50vh;
    height: 50vh;
}
.furImage{
    margin-left: -1.5vh;
    margin-top: 0.05vh;
    width: 130%;
    height: 49.8vh;
    border-radius: 0 50vh 50vh 0;
    cursor: pointer;
}
.semifurImage{
    margin-left: -12vh;
    margin-top: 0.05vh;
    width: 135%;
    height: 49.8vh;
    cursor: pointer;
    border-radius: 50vh 0 0 50vh;
}

.WildData{
    font-family: sans-serif;
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 4px;
    overflow: hidden;
    background: linear-gradient(90deg, #000, #fff, #000);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 3s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    font-weight: 500;
	  animation-duration: 6s;
  }
  
  @keyframes animate {
    0% {
      background-position: -500%;
    }
    100% {
      background-position: 500%;
    }
  }
  .dataa{
    line-height: 5vh;
  }