.head{
    height: 250px;
    width:24rem;
    background-color: rgb(230, 230, 234);
    text-align: center;
    padding: 10px;
  }
  #type{
    font-size: 30px;
    color: rgb(120, 117, 114);
  }
  #rs{
    font-size: 38px;
    color:blue
  }
  #info{
    font-size: 18px;
    color:rgb(120, 117, 114);
    font-weight: 600;
  }
  #card{
    box-shadow: 5px 5px 5px 5px rgb(245, 237, 237);
    transition: transform .8s;
  
  }
  #detail{
    font-size: 18px;
    color: rgb(120, 117, 114);
    font-weight: 500;
    padding:10px;
    line-height: 2;
  }
  #detail2{
    font-size: 18px;
    color: rgb(120, 117, 114);
    font-weight: 500;
  
  }
  #btnbtn{
    background-color: blanchedalmond;
    color:rgb(11, 120, 11);
    font-weight: 500;
    font-size: 18px;
  
  }
  .head:hover{
    color:white;
    background-color: #2a52be;
  }
  #card:hover{
    transform: scale(1.12);
  }
  .head:hover #info{
    color: white;
  }
  .head:hover #rs{
    color:white;
  }
  .head:hover #type{
    color:white;
  }