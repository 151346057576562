.locationfinder{
    height: 7vh;
    width: 50vh;

}
.locationfinder::placeholder{
    font-size: 3vh;
}
.mess{
    color: rgb(148, 148, 148);
    
}
.locationFinder{
    margin-left: -22vh;
}
.find{
    cursor: pointer;
}
#textarea{
    width: 50%;
    height: 7vh;
}
#textarea1,#textarea2{
    width: 50%;
    height: 9vh;
}