/* body {
    margin: 0;
    font-family: 'Muli', sans-serif;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow-x: hidden;
  } */
  
  .background {
    
    /* background: url('https://images.pexels.com/photos/1095624/pexels-photo-1095624.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260')
      center top no-repeat #252746;  */
     background-size: cover;
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }  

  /* .background {
    background: url('https://images.pexels.com/photos/1095624/pexels-photo-1095624.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260')
      center top no-repeat #252746;
    background-size: cover;
    display: block;
    width: 100%;
    height: 1000px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }
   */
  /* .background-texture {
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#0f0140e6),
      to(rgba(255, 214, 117, 0.9))
    );
    background: linear-gradient(to top, #0f0140e6, rgba(255, 214, 117, 0.9));
    background-size: cover;
    width: 100%;
    height: 1000px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  } */

  #fur-header{
    margin:20px;
  }
  #fur-header>h1{
    text-align:center;
    font-size:2rem;
    color: rgb(77, 55, 102);
  }
  
  .carousel {
    width: 100%;
    overflow-x: hidden;
    padding: 30px;
    padding-top: 80px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .carousel__container {
    white-space: nowrap;
    /* margin-right:100px; */
    margin: 30px;
    padding-bottom: 10px ;
    display: inline-block;
  }

  
  .carousel-item {
    width: 200px;
    height: 280px;
    border-radius: 20px;
    background-color: #95bcd6;
    overflow: hidden;
    margin-right: 10px;
    margin-top: 70px;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: 800ms all;
    transition: 800ms all;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    position: relative;
  }
  
  .carousel-item:hover ~ .carousel-item {
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }
  

  
  .carousel__container:hover .carousel-item:hover {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }
  
  .carousel-item__img {
    width: 200px;
    height: 280px;
    -o-object-fit: cover;
    object-fit: cover;
  }
  
  .carousel-item__details {
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.9)),
      to(rgba(0, 0, 0, 0))
    );
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    font-size: 10px;
    opacity: 0;
    -webkit-transition: 450ms opacity;
    transition: 450ms opacity;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .carousel-item__details:hover {
    opacity: 1;
  }
  
  .carousel-item__details span {
    /* width: 10px;
    height: 10px; */
    font-size: 0.9rem;
    color: #2ecc71;
    /* background-color: white; */
  }
  
  .carousel-item__details .controls {
    padding-top: 180px;
  }
  
  .carousel-item__details .carousel-item__details--title,
  .carousel-item__details--subtitle {
    color: #fff;
    margin: 5px 0;
  }
  