aside {
  color: #fff;
  width: 220px;
  padding-left: 20px;
  height: 100vh;
  background-image: linear-gradient(30deg, #0048bd, #44a7fd);
  position: fixed;
}
body{
  background-color: aliceblue;
}
aside a {
  font-size: 16px;
  color: #fff;
  display: block;
  padding: 12px;
  padding-left: 30px;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

aside a:hover {
  color: #3f5efb;
  background: #fff;
  outline: none;
  position: relative;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

aside a i {
  margin-right: 5px;
}

aside a:hover::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 100%;
  right: 0;
  height: 35px;
  width: 35px;
  border-bottom-right-radius: 18px;
  box-shadow: 0 20px 0 0 #fff;
}

aside a:hover::before {
  content: "";
  position: absolute;
  background-color: transparent;
  top: 38px;
  right: 0;
  height: 35px;
  width: 35px;
  border-top-right-radius: 18px;
  box-shadow: 0 -20px 0 0 #fff;
}

aside p {
  font-size: large;
  margin: 0;
  padding: 40px 0;
}
.container-div {
  height: auto;
  width: 100%;
  margin-left: 14%;
}

/* =-== */

.profile-h1 {
  font-size: 50px;
  /* text-transform: uppercase; */
  /* font-family: "Gambetta", serif; */
  font-family: Brush Script MT, Brush Script Std, cursive;
  letter-spacing: 1px;
  font-weight: 400;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  color: black;
  outline: none;
  text-align: center;
}

/* .profile-h1:hover {
  font-variation-settings: "wght" 582;
  letter-spacing: 3px;
} */

.profile-div{
    padding-left: 50px;
    padding-top: 50px;  
    height: 500px;
    border-radius: 50px 50px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.profile-img{
    /* margin-top: -1%; */
    width: 150px;
    height: 150px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;

  }
.profile-img-div{
    /* margin-top: -1%; */
    height: 150px;
    /* border-bottom: 2px solid #3f5efb; */
}
.side-dsn{
    margin-top: -8%;
    height: 500px;
    background-color: #3f5efb;
    border-left: 4px solid gold;
}

#h5{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
/* =========== */

.cross-icon{
  font-size: 22px;
  
}
.card-wishList {
  position: relative;
  width: 16.875em;
  height: 18.5em;
  box-shadow: 0px 1px 13px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 120ms;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0.5em;
  padding-bottom: 3.4em;
}

.card-wishList::after {
  content: "View More";
  padding-top: 1.25em;
  padding-left: 1.25em;
  position: absolute;
  left: 0;
  bottom: -60px;
  background:#3f5efb;
  color: #fff;
  height: 3em;
  width: 100%;
  transition: all 80ms;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0;
}

.card-wishList .title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  position: absolute;
  left: 0.625em;
  bottom: 1.875em;
  font-weight: 400;
  color: #000;
}

.card-wishList .posted {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  position: absolute;
  left: 13em;
  bottom: 0.500em;
  font-weight: 400;
  color: #000;
}

.card-wishList .price {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 0.9em;
  position: absolute;
  left: 0.625em;
  bottom: 0.625em;
  color: #000;
}

.card-wishList:hover::after {
  bottom: 0;
  opacity: 1;
}

.card-wishList:active {
  transform: scale(0.98);
}

.card-wishList:active::after {
  content: "Loading... !";
  height: 3.125em;
}

.text {
  max-width: 55px;
}

.image {
  background: rgb(241, 241, 241);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.image img{
  width: 100%;
  height: 100%;
}
/* =========== */
