.sideFilter{
    margin-top: 9vh;
    height: 70vh;
}
.sideBarButtons:hover{
    background-color: rgb(62, 168, 239);
}
.sideBarButtons{
    border-radius: 1vh;
    background-color: white;
    cursor: pointer;
}
.nothingFound{
    display: flex;
    justify-content: center;
    margin-top: 25vh;
}
.page {
    padding: 40px;
     
  }
  
  #splash {
    background:rgba(0,0,0,0.3);
    background-repeat: repeat-y;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: splash 3s ease-in;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  
  #loader {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%,0);
  }
  #loader:after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -8px;
    bottom: -170px;
    width: 3px;
    background: #fff;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    height: 200px;
  }
  
  #loader:before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: 8px;
    bottom: -190px;
    width: 3px;
    background: #000;
    background: linear-gradient(to bottom, rgba(0,0,0,.2) 0%, rgba(0,0,0,.2) 50%, rgba(0,0,0,0) 100%);
    height: 200px;
  }
  
  #splash .anim {
    height: 100%;
    position: absolute;
    left: 50%;
    width: 100px;
    transform: translate(-50%,100%);
    animation: loader 3s linear;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  
  @keyframes loader {
    0% {
      transform: translate(-50%,110%);
    }
    30% {
      transform: translate(-50%,50%);
    }
    100% {
      transform: translate(-50%,0%);
    }
  }
  
  @keyframes splash {
    0% {
      transform: translate(0%,0%);
    }
    50% {
      transform: translate(0%,0%);
    }
    100% {
      transform: translate(0%,-100%);
    }
  }
  
  /* ------------------------------------------------------------------------------------------------------------------- */
 