.section {
    margin-top: 12vh;
    background-color: white;
    width: 900px !important;
    height: auto;
}

#email,
#pass,
#check1,
#login,
#name,
#contact {
    background-color: transparent;
    margin-left: 10vh;
}

#logimg {
    margin-top: 0.05vh;
}

#log {
    margin-left: 6vh;
    font-size: 3.7vh;
    font-family: candara;
}

#with {
    margin-left: 6vh;

}

#exist {
    margin-left: 8vh;
}

#email,
#pass,
#name,
#contact {
    width: 65%;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid;
}
.fa {
    margin-right: 1vh;
}

#in {

    margin-left: 6vh;
    width: 30%;
}
.otpinput{
    width: 30%;
    height: 6vh;
}

/* -------------------------------------------------------------validation-------------------------------------------------- */

.shake {
    animation: shake 0.5s;
}
@keyframes shake {
    0% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
    20%, 40%, 60%, 80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
  }
  